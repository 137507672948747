import React from 'react';
import styled, { css } from 'styled-components';

import AfiszujsieHashtag from 'components/AfiszujsieHashtag';
import Link from 'components/Link';
import readOpracowaniaSvg from 'assets/icons/read.svg';
import seeGallerySvg from 'assets/icons/look-glasses.svg';
import downloadSvg from 'assets/icons/download.svg';
import { HeroLink } from 'components/HeroSection';
import Icon from 'components/Icon';

import * as urls from '../../../urls';

const texts = [
  {
    icon: readOpracowaniaSvg,
    text: 'Przeczytaj opracowania',
    linkTo: urls.opracowania,
  },
  {
    icon: seeGallerySvg,
    text: 'Zobacz galerię',
    linkTo: urls.galeria,
  },
  {
    icon: downloadSvg,
    text: <React.Fragment>Pobierz paczkę z&nbsp;fontami i&nbsp;ilustracjami</React.Fragment>,
    linkTo: urls.doPobrania,
  },
];

export const StyledWrapper = styled.div``;

export const StyledLinks = styled.div`
  margin-top: 36px;
  display: flex;
  flex-direction: column;
`;

export const StyledLinkToText = styled(HeroLink).attrs(() => ({ as: Link }))`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.font.size18};
  margin-bottom: 8px;
  display: flex;
  margin-right: auto;
  align-items: center;
  text-transform: uppercase;

  ${({ theme }) => css`
    ${theme.mediaQueries.tablet.min} {
      font-size: ${({
        theme: {
          font: { size20 },
        },
      }) => size20};
    }

    ${theme.mediaQueries.largeDesktop.min} {
      font-size: ${({
        theme: {
          font: { size24 },
        },
      }) => size24};
    }
  `}
`;

export const StyledIcon = styled(Icon)`
  margin-right: 16px;
`;

export const StyledAuthor = styled.span`
  display: block;

  ${({ theme }) => css`
    ${theme.mediaQueries.tablet.min} {
      display: inline;
    }
  `}
`;

const HomeHeroSectionContent = () => (
  <StyledWrapper>
    <p>
      „Afiszuj się!” to portal poświęcony historii polskiej międzywojennej reklamy, która
      występowała głównie w&nbsp;formie drukowanej.
    </p>
    <p>
      W&nbsp;galerii znajdziesz ponad 250 reklam, zachęcających do kupna wszelkich możliwych towarów
      – od opon, tzw. pneumatyków, po „nawskroś perfumowane” mydło. Wśród najciekawszych przykładów
      międzywojennej reklamy reprodukowanej są zarówno kolorowe plakaty, afisze, reklamy
      z&nbsp;prasy ilustrowanej, ogłoszenia drobne oraz ulotki, takie jak prospekty czy pocztówki.
    </p>
    <p>
      W&nbsp;dziale opracowania przeczytasz trzy teksty. W&nbsp;pierwszym z&nbsp;nich Aleksandra
      Maria Lewandowska-Ferenc charakteryzuje różne formy reklamy i&nbsp;ogłaszania się,
      w&nbsp;drugim Andrzej Tomaszewski porusza temat typografii oraz metod drukarskich, zaś
      w&nbsp;trzecim Michał Warda pisze o&nbsp;plakacie reklamowym międzywojnia.
    </p>
    <p>
      W&nbsp;zakładce do pobrania znajdują się trzy kroje pisma zdigitalizowane na podstawie
      międzywojennych afiszy – Kolumbia, Renesans i&nbsp;Makkabi, do tego ilustracje z&nbsp;ogłoszeń
      drobnych, instrukcja tworzenia plakatu reklamowego, przykładowe plakaty oraz elementy
      graficzne z&nbsp;plakatów autorstwa Igora Kubika. Całość dostępna jest na otwartej licencji,
      zupełnie za darmo. Zarówno kroje, jak i&nbsp;ilustracje możesz wykorzystać w&nbsp;swoich
      projektach!
    </p>
    <p>#AFISZUJSIE</p>
    <p>
      Pobierz paczkę z&nbsp;krojami i&nbsp;ilustracjami, przygotuj swój własny plakat reklamowy
      i&nbsp;wrzuć go na Instagrama z&nbsp;hasztagiem <AfiszujsieHashtag />.
    </p>

    <StyledLinks>
      {texts.map(({ icon, linkTo, text }) => (
        <StyledLinkToText to={linkTo} key={linkTo}>
          <StyledIcon svg={icon} />
          {text}
        </StyledLinkToText>
      ))}
    </StyledLinks>
  </StyledWrapper>
);

HomeHeroSectionContent.propTypes = {};

export default HomeHeroSectionContent;
