import React from 'react';
import { Helmet } from 'react-helmet';

import SEO from 'components/Seo';
import HeroSection, { PAGES } from 'components/HeroSection';
import { HomeHeroSectionContent } from 'components/PagesSpecific/Home';

const Index = () => (
  <React.Fragment>
    <Helmet>
      <body className="lightGreen" />
    </Helmet>
    <SEO title="Strona główna" />

    <HeroSection page={PAGES.HOME} title="Afiszuj się!">
      <HomeHeroSectionContent />
    </HeroSection>
  </React.Fragment>
);

export default Index;
